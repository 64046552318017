

























































































#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  /*margin-top: 60px;*/
  background-color: #EBEBEBA3;
}

/*this style applies globally*/
.striped-table {
  tbody tr:nth-of-type(odd) {
    background-color: rgb(60, 63, 65, .15);
  }
  table {
    border: 1px solid rgba(0,0,0,.25);
  }
  th {
    border-bottom: thin solid rgba(0,0,0,.62) !important;
  }
}
.scroll-pad {
  scroll-margin-top: 80px;
}
