
















































































































.landing-page-inner {
  max-width: 800px;
  margin: 0 auto
}
.landing-page-outer {
  max-width: 90%;
  margin: 0 auto
}
